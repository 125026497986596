import axios, { AxiosError } from "axios";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

export const baseUrl = process.env.REACT_APP_API_URL;
export const fileURL = process.env.REACT_APP_STORAGE_URL;

export const useFetch = <T>(url: string, key: string, enabled?: boolean) => {
  const navigate = useNavigate();

  const [isEnabled, setIsEnabled] = useState(enabled);

  useEffect(() => {
    if (enabled === undefined) setIsEnabled(true);
  }, []);

  const fetchData = async (): Promise<T> => {
    const response = await axios.get<T>(`${baseUrl}${url}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const query = useQuery<T, AxiosError>(key, fetchData, {
    enabled: isEnabled, // Only enable if isEnabled is true
  });

  useEffect(() => {
    if (enabled === undefined) {
      query.refetch();
    }
  }, [url]);

  return query;
};

interface InputFile {
  name: string;
  value: string;
  type: string;
}

export type FilesState = Record<string, File>;

type CheckedArray = Record<string, string[]>;

export interface SelectedOption {
  value: string | number;
  label: string;
}

export interface ArrayObject {
  id: any;
  value: any;
  deleted?: boolean;
  uploaded?: boolean;
}

export const usePOST = <FormDataType extends Record<string, any>>(
  initialData: FormDataType,
  actionSuccess: (data: any) => void,
  actionError: (error: any) => void
) => {
  const [formData, setFormData] = useState<FormDataType>(initialData);
  const [images, setImages] = useState<FilesState>({});
  const [viewfile, setViewFile] = useState<string>();
  const [viewFiles, setViewFiles] = useState<InputFile[]>([]);
  const [checkedArray, setCheckedArray] = useState<CheckedArray>({});

  const prepareFormData = useCallback(
    (data?: FormDataType) => {
      const formDataToSend = new FormData();
      if (data) {
        for (const [key, value] of Object.entries(data)) {
          formDataToSend.append(key, value as string | Blob);
        }
      } else {
        for (const [key, value] of Object.entries(formData)) {
          formDataToSend.append(key, value as string | Blob);
        }
      }

      if (checkedArray) {
        for (const [key, value] of Object.entries(checkedArray)) {
          for (let i = 0; i < value.length; i++) {
            formDataToSend.append(key, value[i]);
          }
        }
      }

      if (images) {
        for (const [key, value] of Object.entries(images)) {
          formDataToSend.append(`images[${key}]`, value);
        }
      }

      return formDataToSend;
    },
    [formData, checkedArray, images]
  );

  const mutation = useMutation(
    async ({ url, data }: { url: string; data?: FormDataType }) => {
      const formDataToSend = prepareFormData(data);
      const res = await axios.post(`${baseUrl}${url}`, formDataToSend, {
        headers: {
          Accept: "application/json",
        },
      });
      return res;
    },
    {
      onSuccess: (data) => {
        actionSuccess(data);
      },
      onError: (error) => {
        actionError(error);
        mutation.reset();
      },
    }
  );

  const prepareArrayOfObjects = (
    arrayOfarrayObject: ArrayObject[][],
    name: string[]
  ) => {
    let newFormData = formData;
    arrayOfarrayObject.forEach((arrayObject, arrIndex) => {
      arrayObject
        .filter((ob) => ob.uploaded && !ob.deleted)
        .forEach(
          (ob, index) =>
            (newFormData = {
              ...newFormData,
              [`${name[arrIndex]}[${index}]`]: ob.value,
            })
        );
      arrayObject
        .filter((ob) => ob.deleted && !ob.uploaded)
        .forEach(
          (ob, index) =>
            (newFormData = {
              ...newFormData,
              [`trash_${name[arrIndex]}[${index}]`]: ob.id,
            })
        );
    });

    return newFormData;
  };

  const handleSubmit = (url: string, data?: FormDataType) => {
    mutation.mutate({ url, data });
  };

  const handleChangeSelect = (
    selected: SelectedOption | SelectedOption[],
    active: { name: string }
  ) => {
    setFormData((prev) => ({
      ...prev,
      ["option_" + active.name]: selected,
      [active.name]: Array.isArray(selected)
        ? selected.map((e) => e.value)
        : selected.value,
    }));
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files, checked } = event.target;
    let currentValue: string | File | boolean | undefined | 1 | 0;
    console.log(value);

    if (type === "file") {
      let file = (currentValue = files ? files[0] : undefined);
      const showfile = URL.createObjectURL(file as Blob | MediaSource);
      setViewFile(showfile);
      event.target.value = "";
    } else if (type === "checkbox") {
      currentValue = checked ? 1 : 0;
    } else {
      currentValue = value;
    }
    setFormData((prev) => ({ ...prev, [name]: currentValue }));
  };

  const handleChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeArrayFiles = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const newFilesObject: FilesState = {};

    if (files) {
      const currentImagesCount = Object.keys(images).length;
      for (let i = 0; i < files.length; i++) {
        newFilesObject[`${currentImagesCount + i}`] = files[i];
      }

      setImages((prevImages) => ({
        ...prevImages,
        ...newFilesObject,
      }));

      const urlImages: InputFile[] = [];
      for (let i = 0; i < files.length; i++) {
        if (!files[i]) continue;
        urlImages.push({
          name: files[i].name,
          value: URL.createObjectURL(files[i]),
          type: files[i].type,
        });
      }

      setViewFiles((prev) => [...prev, ...urlImages]);

      event.target.value = "";
    }
  };

  const handleCheckedArray = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked, name } = e.target;
    if (checked) {
      setCheckedArray((prev) => ({
        ...prev,
        [name]: prev[name] ? [...prev[name], value] : [value],
      }));
    } else {
      setCheckedArray((prev) => ({
        ...prev,
        [name]: prev[name].filter((p) => p !== value),
      }));
    }
  };

  return {
    handleSubmit,
    formData,
    setFormData,
    handleChangeInput,
    handleChangeTextArea,
    handleChangeArrayFiles,
    images,
    viewFiles,
    viewfile,
    setImages,
    handleCheckedArray,
    handleChangeSelect,
    mutation,
    setViewFile,
    prepareArrayOfObjects,
  };
};

export const useDelete = (
  actionSuccess: (data?: any) => void,
  actionError: (data?: any) => void
) => {
  const mutation = useMutation(
    (url: string) =>
      axios
        .delete(baseUrl + url, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        actionSuccess(data);
      },
      onError: (error) => {
        actionError(error);
      },
    }
  );

  const deleteItem = (url: string) => {
    mutation.mutate(url);
  };

  return { deleteItem, mutation };
};

export const useFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams.toString());

  const handleParamsClick = (
    name: string,
    value: string | string[] | number
  ) => {
    if (Array.isArray(value)) {
      if (value.length > 0) searchParams.set(name, value.join(","));
      else searchParams.delete(name);
    } else if (value) {
      newSearchParams.set(name, value.toString());
      setSearchParams(newSearchParams);
    }
  };

  const handleParamDelete = (name: string) => {
    newSearchParams.delete(name);
    setSearchParams(newSearchParams);
  };

  const handleParamsDeleteAll = () => {
    searchParams.forEach((key) => newSearchParams.delete(key));
    setSearchParams(newSearchParams);
  };

  const handlePageClick = (page: number) => {
    handleParamsClick("page", page);
  };

  return {
    searchParams,
    handlePageClick,
    handleParamsClick,
    handleParamDelete,
    handleParamsDeleteAll,
  };
};

// const logoutRequest = async (url: string, token: string): Promise<void> => {
//   await axios.post(
//     `${baseUrl}${url}`,
//     {},
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };

// export const useLogout = (actionError: () => void) => {
//   const mutation = useMutation<void, Error, string>({
//     mutationFn: (url) => logoutRequest(url, cookies.token),
//     onSuccess: () => {

//       window.location.reload();
//     },
//     onError: (_error) => {
//       actionError();
//     },
//   });

//   return mutation;
// };

interface UseCloseReturnType {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mouse: React.RefObject<HTMLDivElement>;
}

export const useClose = (): UseCloseReturnType => {
  const [open, setOpen] = useState<boolean>(false);
  const mouse = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (mouse.current && !mouse.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return { open, setOpen, mouse };
};

// export const RequireAuth: React.FC<{
//   children: ReactElement<any, any> | null;
// }> = ({ children }) => {
//   const [cookies] = useCookies(["token"], { doNotParse: true });
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!cookies.token) {
//       navigate("/login");
//     }
//   }, [cookies.token, navigate]);

//   return children;
// };

export const clickZoomInImage = (event: React.MouseEvent<HTMLImageElement>) => {
  event.preventDefault();
  const target = event.target as HTMLImageElement;
  if (target.requestFullscreen) {
    target.requestFullscreen();
  }
};
