import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import arrow from "../../assets/icons/next.png";
import { Link, useNavigate } from "react-router-dom";

function Slider({ gap = 10, dist = 250, children, isComments, page, id = 1 , isOne = false}) {
  const [Url, setUrl] = useState(
    `${process.env.REACT_APP_API_URL}/get_all_comments_of_unit_by_unit_id/${id}`
  );
  //   console.log("page", page);
  //   console.log("url-->", Url);
  useEffect(() => {
    if (page === "subject") {
      setUrl(
        `${process.env.REACT_APP_API_URL}/get_all_comments_by_subject_id/${id}`
      );
    } else if (page === "unit") {
      setUrl(
        `${process.env.REACT_APP_API_URL}/get_all_comments_of_unit_by_unit_id/${id}`
      );
    } else if (page === "lesson") {
      setUrl(
        `${process.env.REACT_APP_API_URL}/get_all_comments_of_lesson_by_lesson_id/${id}`
      );
    }
  }, []);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/allComments", { state: { url: Url } });
  };

  const SliderRef = useRef();

  const handleRightClick = (e) => {
    SliderRef.current.scrollLeft += dist;
  };

  const handleLeftClick = (e) => {
    SliderRef.current.scrollLeft -= dist;
  };

  return (
    <div className="subject__popComments">
      <div className="comments__arrows">
        <div className="rightArrow" onClick={(e) => handleRightClick(e)} style={{display: `${isOne ? 'none': 'block'}`}}>
          <img loading="lazy"  src={arrow} alt="" />
        </div>
        <div className="leftArrow" onClick={(e) => handleLeftClick(e)} style={{display: `${isOne ? 'none': 'block'}`}}>
          <img loading="lazy"  src={arrow} alt="" />
        </div>
        <div
          className="comments__container d-flex " 
          style={{ 
            gap: gap , 
            justifyContent: isOne ? 'center !important' : 'flex-start !important',
            alignItems: 'flex-start'
          }}
          ref={SliderRef}
        >
          {children}
        </div>
 
 <div className='comment text-center '>
        <p onClick={handleNavigate} className=" me-2  ms-2 CursorLink_comment my-auto">
          {isComments && "كل التعليقات"}
        </p>
        </div>
      </div>
    </div>
  );
}

export default Slider;
