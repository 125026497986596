import React from 'react';

const LoadingOverlay = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="loading-overlay">
            <div className="loader-container">
                {/* Modern Loader */}
                <div className="loader"></div>

                {/* Loading Text */}
                <p className="loading-text">
                    جاري تحميل البيانات، الرجاء الانتظار...
                </p>
            </div>
        </div>
    );
};

export default LoadingOverlay;
