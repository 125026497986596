import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CustomLink.css'; 

const CustomLink = ({ to, isDisabled, children, className, ...props }) => {
  if (isDisabled) {
    return (
      <span
        className={`custom-link disabled ${className || ''}`}
        aria-disabled="true"
        role="link"
        tabIndex="-1"
        {...props}
      >
        {children}
      </span>
    );
  }

  return (
    <Link
      to={to}
      className={`custom-link ${className || ''}`}
      {...props}
    >
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CustomLink.defaultProps = {
  isDisabled: false,
  className: '',
};

export default CustomLink;
