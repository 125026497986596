import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCommentDots } from 'react-icons/fa'; // Import an icon

const FloatingButton = () => {
  const navigate = useNavigate();

  const handleSupportButtonClick = () => {
    navigate('/support/chat/participants');
  };

  return (
    <button
      className="floating-button"
      onClick={handleSupportButtonClick}
      aria-label="Support Chat"
    >
      <FaCommentDots />
    </button>
  );
};

export default FloatingButton;
