import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useRoutes } from 'react-router-dom';
import axios from 'axios';
import {
	setError,
	setSuccess,
} from '../../../redux-toolkit/reducers/notification';
import { useDeferredValue } from 'react';
import { useDispatch } from 'react-redux';

import imageCompression from 'browser-image-compression';


export default function ExamTraditionalLogic() {
	const [fileTypes, setFileTypes] = useState(['JPG', 'PNG', 'GIF']);
	const [examTime, setExamTime] = useState();
	const [startExam, setStartExam] = useState(false);
	const [examIsFinished, setExamIsFinished] = useState(false);
	const [file, setFile] = useState(null);
	const [progress, setProgress] = useState(0);
	const [allFiles, setAllFiles] = useState([]);
	const [QuizQuestions, setQuizQuestions] = useState([]);
	const [QuizDetails, setQuizDetails] = useState({});
	const quiz_id = useParams();
	const [ImagesData, setImagesData] = useState([]);
	const [AnswersFile, setAnswersFile] = useState();
	const [loading, setloading] = useState(true);
	const [loadFinish, setloadFinish] = useState(false);
	const [isCompressing, setIsCompressing] = useState(false);
	const [examInfos, setExamInofs] = useState({});
	const [isAnswerOnTradional, setIsAnserOnTraditional] = useState(false);
	const token = localStorage.getItem('token');
	const [automatedQuestions, setAutomatedQuestions] = useState();
	const dispatch = useDispatch();
	const navigate = useNavigate();


	const handleChange = async (file) => {

		// console.log('originalFile instanceof Blob file file file file ', file[0] instanceof Blob); // true

		let originSize = 0;
		let compressedSize = 0;

		const compressingImages = [];

		// compressing images
		async function compressingFiles(files) {
			setIsCompressing(true);
			// options for compressing files
			const options = {
				maxSizeMB: 25,
				maxWidthOrHeight: 8000,
				useWebWorker: true,
				initialQuality: .5,
				alwaysKeepResolution: false
			}

			for (let i = 0; i < files.length; i++) {

				originSize += files[i].size / 1024 / 1024;

				imageCompression(files[i], options).then((compressedFile) => {
					compressedSize += compressedFile.size / 1024 / 1024;
					compressingImages.push(compressedFile);
					setImagesData([...ImagesData, ...compressingImages]);
					setFile([...compressingImages]);
					setIsCompressing(false);


				}).catch(error => {
				});
			}
		}
		await compressingFiles(file);

	};

	useEffect(() => {
		let size = 0;
		if (ImagesData.length > 0) {
			for (let i = 0; i < ImagesData.length; i++) {
				size += ImagesData[i].size / 1024 / 1024;
			}
		}
	}, [ImagesData])

	


	useEffect(() => {
		const sliderImages = [...allFiles];
		let url;

		if (file) {

			file.map((eachFile, index) => {
				url = window.URL.createObjectURL(eachFile);
				sliderImages.push(url);
			})
		}

		setAllFiles(sliderImages);
	}, [file]);



	const getAllQuizQuestions = async () => {

		await axios

			.get(
				`${process.env.REACT_APP_API_URL}/get_traditional_exam_by_id/${quiz_id.id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((data) => {
				setQuizQuestions(data.data.data.questions);
				setExamInofs({ ...data.data.data });
				if (data.data.data?.type == 'BOTH' || data.data.data.type == 'AUTOMATED') {

					fetch(
						`${process.env.REACT_APP_API_URL}/automated-quizzes/${data.data.data.automated_quiz_id}/questions`,
						{
							headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
						}
					)
						.then((response) => response.json())
						.then((data) => {


							setAutomatedQuestions(data.data);
						})
						.then((error) => {

						});
				}
			})
			.catch((error) => {
			});
	};
	const getAllQuizDetails = async () => {
		await axios

			.get(
				`${process.env.REACT_APP_API_URL}/get_traditional_exam_by_id/${quiz_id.id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((data) => {
				setQuizDetails(data.data.data);
				setExamTime(data?.data?.data?.quiz_duration);
			})
			.catch((error) => {
			});
	};
	const SavedAnswersFiles = async () => {
		setloadFinish(true);
		setloading(false);
		let formData = new FormData();
		formData.append('traditional_exam_id', quiz_id.id);
		for (let i = 0; i < ImagesData.length; i++) {
			formData.append(`image_answers[${i}]`, ImagesData[i]);
		}
	

		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/post_student_traditional_exam_answers`,
				formData,
				{
					headers: {
						Accept: 'aplication/json',
						Authorization: `Bearer ${token}`,
					},
					onUploadProgress: (progressEvent) => {
						const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
						setProgress(progress);
					},
				}
			)
			.then((data) => {
				dispatch(setSuccess('تم انجاز الاختبار'));
				setExamIsFinished(true);
				setAnswersFile(data.data.data.correction_Ladder_file);
				setloading(true);
				
			})
			.catch((error) => {
				error?.response?.data?.message === 'validation.required'
					? dispatch(setError(' يجب ادخال الاجوبة'))
					: error?.response?.data?.message ===
						'the student already have answers in this quiz'
						? dispatch(setError('تم انجاز الاختبار سابقا'))
						:
						(
							error?.response?.data?.message == 'validation.max.file' ?

								dispatch('يجب أن لا يتجاوز حجم الصور ال 50 ميغابايت')
								:
								dispatch(setError(error?.response?.data?.message))


						)
				setloadFinish(false);
				setloading(true);
			}).finally(() => {
				setProgress(0);
			})
	};




	useEffect(() => {
		getAllQuizQuestions();
		getAllQuizDetails();
	}, []);

	return {
		fileTypes,
		examTime,
		startExam,
		examIsFinished,
		file,
		allFiles,
		handleChange,
		AnswersFile,
		setStartExam,
		setExamIsFinished,
		QuizQuestions,
		QuizDetails,
		SavedAnswersFiles,
		setloading,
		loading,
		loadFinish,
		progress,
		isCompressing,
		examInfos,
		isAnswerOnTradional,
		setIsAnserOnTraditional,
		automatedQuestions
	};
}
