import { createContext, useEffect, useState } from "react";
import "./App.css";
import AllRoutes from "./routes";
import axios from "axios";
import FloatingButton from "./components/FloatingButton";
import { ProfileContext } from "./contexts/ProfileContext";
import { useFetch } from "./hooks/API/index.ts";

export const InfoContext = createContext(null);

function App() {
  const [profileData, setProfileData] = useState(null);
  const { data } = useFetch("/info", "info");

  useEffect(() => {
    // if the use was student
    if (localStorage.getItem("role") == 1) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/get_student_by_token`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token") ||
              localStorage.getItem("guardian_token")
            }`,
          },
        })
        .then((response) => {
          // set the account situation to know how to handle user permessions inside the website
          localStorage.setItem("is_active", response.data.data.is_active);
          localStorage.setItem(
            "has_subscribed_before",
            response.data.data.has_subscribed_before
          );

          if (
            response.data.data.is_active == 0 &&
            !window.location.pathname.includes("notactive")
          ) {
            window.location.replace("/notactive");
          }
        })
        .then((data) => {})
        .catch((error) => {
          if (error.response.data.code == 401) {
            window.location.reload();
            localStorage.removeItem("token");
            localStorage.removeItem("role");
            localStorage.removeItem("has_subscribed_before");
            if (localStorage.getItem("token2")) {
              localStorage.setItem("token", localStorage.getItem("token2"));
              localStorage.setItem("role", localStorage.getItem("role2"));
              localStorage.removeItem("token2");
              localStorage.removeItem("role2");
            }

            window.location.replace("/");
          }
        })
        .finally(() => {});
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/get_teacher_profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const profileData = response.data;
          setProfileData(profileData);
          console.log("Profile Data:", profileData);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          if (error.response && error.response.status === 401) {
            // window.location.replace('/');
          }
        });
    }
  }, []);

  return (
    <ProfileContext.Provider value={profileData}>
      <InfoContext.Provider value={data?.data}>
        <FloatingButton />
        <AllRoutes />
      </InfoContext.Provider>
    </ProfileContext.Provider>
  );
}

export default App;
