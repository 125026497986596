import { configureStore } from "@reduxjs/toolkit";
import SignReducer from "./reducers/Auth/Auth";
import AllCategoriesReducer from "./reducers/AllCategories/AllCategoriesSlice";
import AllCommentsReducer from "./reducers/AllComments/AllCommentsSlice";
import HomeReducer from "./reducers/Home/HomeSlice";
import RulesReducer from "./reducers/Rules/RulesSlice";
import FooterReducer from "./reducers/FooterInfo/FooterSlice";
import OpinionReducer from "./reducers/Opinion/OpinionSlice";
import PostCommentLessonReducer from "./reducers/PostCommentLesson/PostCommentLessonSlice";
import SubjectCommentReducer from "./reducers/SubjectComments/SubjectCommentsSlice";
import UnitCommentReducer from "./reducers/UnitComment/UnitCommentSlice";
import PostCommentUnitReducer from "./reducers/PostCommentUnit/PostCommentUnitSlice";
import PostStudentSubscribtionSlice from "./reducers/PostStudentSubscribtion/PostStudentSubscribtionSlice";
import LoginAuthReducer from "./reducers/LoginAuth/LoginAuth";
import GetPointsReducer from "./reducers/GetPonits/GetPoints";
import PostContactUsReducer from "./reducers/PostContactUs/PostContactUsSlice";
import UnitTestReducer from "./reducers/Unit_test/UnitSlice";
import LessonReducer from "./reducers/Lesson/LessonSlice";
import SubjectsReducer from "./reducers/Subjects/SubjectsSlice";
import unitsReducer from "./reducers/Units/UnitSlice";
import PostOpinion from "./reducers/PostOpinion/postOpinionSlice";
import notification from "./reducers/notification";

export const store = configureStore({
  reducer: {
    categories: AllCategoriesReducer,
    allComments: AllCommentsReducer,
    SIGN: SignReducer,
    home: HomeReducer,
    rule: RulesReducer,
    footer: FooterReducer,
    opinion: OpinionReducer,
    postOpinion: PostOpinion,
    postComment: PostCommentLessonReducer,
    subjectComment: SubjectCommentReducer,
    unitComment: UnitCommentReducer,
    postUnit: PostCommentUnitReducer,
    PostStudentSub: PostStudentSubscribtionSlice,
    LoginAuth: LoginAuthReducer,
    points: GetPointsReducer,
    postContact: PostContactUsReducer,
    unitTest: UnitTestReducer,
    subjects: SubjectsReducer,
    units: unitsReducer,
    lesson: LessonReducer,
    notification: notification,
  },
});
