import Paper from "../Pages/Paper";

const PaperRoutes = [
    {
        path:'/paper',
        element: <Paper/>,
      
    },


];

export default PaperRoutes;