import React, { useState } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerCircularImage } from "react-shimmer-effects";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -450px 0;
  }
  100% {
    background-position: 450px 0;
  }
`;

const ShimmerDiv = styled.div`
  height: 30px;
  width: 250px;
  border-radius: 8px;
  background: #25918f70;
  background: linear-gradient(
    to right,
    #25918f70 8%,
    #f0f0f0 18%,
    #238a8780 33%
  );
  background-size: 800px 104px;
  animation: ${shimmer} 2s infinite linear forwards;
`;
const MessagesLoader = () => {
  const messages = [
    {
      direction: "rtl",
    },
    {
      direction: "ltr",
    },
    {
      direction: "rtl",
    },
    {
      direction: "ltr",
    },
    {
      direction: "rtl",
    },
    {
      direction: "ltr",
    },
  ];

  return (
    <>
      {messages.map((msg) => {
        return (
          <div
            style={{
              direction: msg.direction,
            }}
          >
            <ShimmerDiv />
          </div>
        );
      })}
    </>
  );
};

export default MessagesLoader;
