import React, { useState } from 'react';
import axios from 'axios';

const StreamDownloader = () => {
    const streamUrl = "https://backend.myway.edu.sy/api/proxy-stream";
    const [progress, setProgress] = useState(0);
    const [speed, setSpeed] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = async () => {
        setIsDownloading(true);
        setProgress(0);
        setSpeed(0);

        try {
            const fileSize = await getFileSize(streamUrl);
            const chunkSize = 1024 * 200;
            const chunkCount = Math.ceil(fileSize / chunkSize);
            const promises = [];
            const startTime = Date.now(); 

            for (let i = 0; i < chunkCount; i++) {
                const start = i * chunkSize;
                const end = Math.min(fileSize - 1, (i + 1) * chunkSize - 1);

                promises.push(
                    downloadChunk(streamUrl, start, end, i, chunkCount, startTime)
                );
            }

            const chunks = await Promise.all(promises);

            // Merge all chunks
            const blob = new Blob(chunks);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'downloaded-file.mp4'; // Replace with desired file name
            link.click();

            setIsDownloading(false);
            setProgress(100);
            setSpeed(0); // Reset speed after download completion
        } catch (error) {
            console.error('Error downloading file:', error);
            setIsDownloading(false);
        }
    };

    const getFileSize = async (url) => {
        const response = await axios.head(url);
        return parseInt(response.headers['content-length'], 10);
    };

    const downloadChunk = async (url, start, end, index, totalChunks, startTime) => {
        const response = await axios.get(url, {
            headers: {
                Range: `bytes=${start}-${end}`,
            },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                const elapsedTime = (Date.now() - startTime) / 1000; // Elapsed time in seconds
                const totalDownloaded = progressEvent.loaded + start; // Total bytes downloaded so far
                const currentSpeed = (totalDownloaded / 1024 / 1024) / elapsedTime; // Speed in MB/s

                const percentCompleted =
                    (index / totalChunks) * 100 +
                    (progressEvent.loaded / progressEvent.total) * (100 / totalChunks);

                setProgress(Math.min(Math.round(percentCompleted), 100));
                setSpeed(currentSpeed.toFixed(2)); // Update speed with 2 decimal places
            },
        });
        return response.data;
    };

    return (
        <div>
            <h3>Parallel Downloader</h3>
            <button onClick={handleDownload} disabled={isDownloading}>
                {isDownloading ? 'Downloading...' : 'Download'}
            </button>
            {isDownloading && (
                <div style={{ marginTop: '10px' }}>
                    <p>Progress: {progress}%</p>
                    <p>Speed: {speed} MB/s</p>
                </div>
            )}
        </div>
    );
};

export default StreamDownloader;
