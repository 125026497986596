import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Layouts/PrimaryLayout/Header/Header";
import Message from "./Message";
import MessageInput from "./MessageInput";
import { useParams } from "react-router-dom";
import axios from "axios";
import RecorpPopup from "./RecorpPopup";
import "react-h5-audio-player/lib/styles.css";
import PopupDelete from "./PopupDelete";
import moment, { duration } from "moment";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import MessagesLoader from "./MessagesLoader";
import Popup from "./Popup";

function SupportChat() {

  const [messages, setMessages] = useState([]);
  const messageQueue = useRef([]);
  const isSending = useRef(false);
  const [replyToMessage, setReplyToMessage] = useState(null);
  const messageRefs = useRef({});
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  const [highlightTrigger, setHighlightTrigger] = useState(Date.now());
  const [isFetching, setisFetching] = useState(false);
  const [delpopup, setDelpopup] = useState(false);
  const [viewFile, setViewFile] = useState();
  const [closefile, setClosefile] = useState(false);
  const [record, setRecord] = useState(false);
  const { subjectId, userId } = useParams();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [scrollTo, setScrollTo] = useState("");
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);








  const isMessageExists = (id) => {
    return messages.some((message) => message.id === id);
  };
  const handleReplyClick = async (replyId) => {
    const exisitingReplyMessage = isMessageExists(replyId);
    if (!exisitingReplyMessage) {
      try {
        await fetchMessages(replyId, true);
      } catch (error) {
        console.error("Failed to fetch messages:", error);
        return;
      }
    }
    const timer = setTimeout(() => {
      const targetElement = messageRefs.current[replyId];
      if (targetElement) {
        const headerOffset = 190;
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        setHighlightedMessageId(replyId);
        setHighlightTrigger(Date.now());
      }
    }, 500);
  };

  const { open, setOpen, mouse } = useClose();

  const processQueue = async () => {
    if (isSending.current) return;
    isSending.current = true;

    while (messageQueue.current.length > 0) {
      const nextMessage = messageQueue.current[0];
      try {
        await postMessage(nextMessage);
        messageQueue.current.shift();
      } catch (error) {
        console.error("Error sending message:", error);
        messageQueue.current.shift();
      }
    }

    isSending.current = false;
  };
  const addToQueue = (message) => {
    messageQueue.current.push(message);
    if (!isSending.current) {
      processQueue();
    }
  };

  const sendMessage = (msg, isFile = false, type = "text") => {
    const newMessage = {
      id: -1 - messages.length,
      status: "sending",
      content: isFile ? "" : msg,
      file_url: isFile ? URL.createObjectURL(msg) : null,
      binary_file: isFile ? msg : null,
      type: type,
      created_at: new Date().toISOString(),
      is_sent_by_auth_user: true,
      read_at: null,
      reply: replyToMessage,
      cs_time: Date.now(),
    };

    setScrollTo((prevScrollTo) =>
      prevScrollTo === "down" ? "down-reset" : "down"
    );
    setIsLastPage(false);
    setMessages([...messages, newMessage]);
    addToQueue(newMessage);
    setReplyToMessage(null);
  };
  const postMessage = async (message) => {
    const formData = new FormData();
    formData.append("content", message.content);
    formData.append("type", message.type);
    formData.append("cs_time", message.cs_time);

    if (message.binary_file) {
      formData.append("file", message.binary_file);
    }
    if (replyToMessage) {
      formData.append("message_id", replyToMessage.id);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/chat/support/users/${userId}/messages`,
        formData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setMessages((prevMessages) => {
              return prevMessages.map((msg) =>
                msg.id === message.id
                  ? {
                    ...msg,
                    progress: progress,
                  }
                  : msg
              );
            });
          },
        }
      );

      const resMessage = response?.data?.data;

      setMessages((prevMessages) => {
        return sortMessages(
          removeFirstDuplicates(
            prevMessages.map((msg) =>
              msg.id === message.id
                ? {
                  ...msg,
                  status: "sent",
                  id: resMessage.id,
                  created_at: resMessage.created_at,
                }
                : msg
            )
          )
        );
      });
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) =>
        sortMessages(
          removeFirstDuplicates(
            prevMessages.map((msg) =>
              msg.id === message.id ? { ...msg, status: "failed" } : msg
            )
          )
        )
      );
    }
  };


  useEffect(() => {
    if (viewFile) setClosefile(true);
    else setClosefile(false);
  }, [viewFile]);


  const handleScroll = () => {
    if (document.documentElement.scrollTop === 0 && !isLoading && !isLastPage) {
      // console.log(document.documentElement.scrollTop, "scroll");
      setPage((prevPage) => prevPage + 1);
      setIsLoading(true);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mimeType = 'audio/webm';
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        console.warn(`${mimeType} is not supported on this browser, falling back to default codec.`);
      }
      const recorder = new MediaRecorder(stream, { mimeType });
      const chunks = [];
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
      recorder.onstop = async () => {
        setRecordedChunks(chunks);
        stream.getTracks().forEach(track => track.stop());
      };
      recorder.start();
      setIsRecording(true);
      setMediaRecorder(recorder);
      setMediaStream(stream);
    } catch (error) {
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
  };

  useEffect(() => {
    if (recordedChunks.length === 0) return;
    const blob = new Blob(recordedChunks, { type: "audio/aac" });
    setAudioBlob(blob);
  }, [recordedChunks]);

  
  const removeFirstDuplicates = (array) => {
    const seen = new Set();
    let result = [];

    for (let i = array.length - 1; i >= 0; i--) {
      const item = array[i];
      if (!seen.has(item.id)) {
        seen.add(item.id);
        result.push(item);
      }
    }
    return result.reverse();
  };

  const sortMessages = (array) => {
    const negatives = array.filter((item) => item.id < 0);
    const nonNegatives = array.filter((item) => item.id >= 0);
    nonNegatives.sort((a, b) => a.id - b.id);
    return [...nonNegatives, ...negatives];
  };

  const fetchMessages = async (msgId, isNewMessages = false) => {
    setisFetching(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL
        }/chat/support/users/${userId}/messages?${(isNewMessages ? `lmid=` : `fmid=`) + msgId
        }`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        let resMessages = res?.data?.data?.data?.reverse();
        setMessages((prev) => {
          let combinedMessages = [];
          if (isNewMessages) {
            combinedMessages = [...prev, ...resMessages];
          } else {
            combinedMessages = [...resMessages, ...prev];
          }
          combinedMessages = sortMessages(
            removeFirstDuplicates(combinedMessages)
          );

          return combinedMessages;
        });

        setData(res?.data);
        setIsLoading(false);

        if (resMessages?.length == 0 && !isNewMessages) {
          setIsLastPage(true);
        } else if (!isNewMessages) {
          setScrollTo((prevScrollTo) =>
            prevScrollTo === "top" ? "top-reset" : "top"
          );
          if (page === 1) {
            setScrollTo("down");
          }
        }
        setisFetching(false);
      });
  };

  useEffect(() => {
    if (scrollTo.includes("down")) {
      window.scrollTo(0, document.body.scrollHeight);
    } else if (scrollTo.includes("top")) {
      window.scrollTo(0, 5);
    }
  }, [scrollTo]);

  useEffect(() => {
    const isSendingMessages = () => {
      return messages.some((msg) => msg.status === "sending");
    };

    if (!isSendingMessages()) {
      const intervalId = setInterval(() => {
        if (!isFetching) {
          const lastMsg = messages
            .slice()
            .find((msg) => msg.id > 0 && !msg.read_at);
          const lastMsgId = lastMsg ? lastMsg.id : -1;
          fetchMessages(lastMsgId, true);
        }
      }, 20000);

      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    let firstMsgId = messages.length > 0 ? messages[0]?.id : -1;
    fetchMessages(firstMsgId);
  }, [page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading]);

  const handleUploadFile = (e, type) => {
    const { files } = e.target;
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const allowedTypes =
      type === "file"
        ? ["application/pdf"]
        : type === "image"
          ? ["image/jpeg", "image/jpg", "image/png", "image/gif"]
          : [];

    if (files[0].size > MAX_FILE_SIZE) {
      setValidationErrors("الحد الأعلى المسموح لحجم الملف هو 5 Mb");
      e.target.value = "";
      return;
    }

    if (!allowedTypes.includes(files[0].type) && type === "file") {
      setValidationErrors("لا يمكن اختيار ملف من غير نوع pdf ");
      e.target.value = "";
      return;
    } else if (!allowedTypes.includes(files[0].type) && type === "image") {
      setValidationErrors(
        "لا يمكن اختيار ملف من غير نوع صورة باللواحق : jpeg,jpg,png,gif. "
      );
      e.target.value = "";
      return;
    }

    sendMessage(files[0], true, type);

    e.target.value = "";
  };

  const renderMessagesWithDateSeparators = () => {
    let lastDate = null;
    const result = [];
    messages.forEach((message, index) => {
      const messageDate = moment(message.created_at).format("YYYY-MM-DD");
      if (messageDate !== lastDate && !message.is_shimmer) {
        const isCurrentYear = moment(messageDate).isSame(moment(), "year");
        const formattedDate = isCurrentYear
          ? moment(messageDate).format("MMMM D")
          : moment(messageDate).format("MMMM D, YYYY");
        result.push(
          <div
            key={`date-${messageDate}`}
            style={{
              textAlign: "center",
              width: "200px",
              color: "black",
              margin: "10px auto",
              fontWeight: "bold",
              backgroundColor: "rgb(36 142 140 / 23%)",
              borderRadius: "30px",
              padding: "5px",
            }}
          >
            {formattedDate}
          </div>
        );
        lastDate = messageDate;
      }
      result.push(
        <div ref={(el) => (messageRefs.current[message.id] = el)}>
          <Message
            messages={messages}
            handleReplyClick={handleReplyClick}
            fetchMessages={fetchMessages}
            message={message}
            setReplyToMessage={setReplyToMessage}
            highlightedMessageId={highlightedMessageId}
            highlightTrigger={highlightTrigger}
            chatUser={data?.user}
          />
        </div>
      );
    });

    return result;
  };

  const formatLastActive = (lastActiveAt) => {
    const date = moment(lastActiveAt);
    const isCurrentYearAndMonth =
      date.isSame(moment(), "month") && date.isSame(moment(), "year");

    return isCurrentYearAndMonth
      ? date.format("hh:mm a")
      : date.format("YYYY-MM-DD hh:mm a");
  };

  return (
    <div>
      {validationErrors && (
        <Popup
          setValidationErrors={setValidationErrors}
          validationErrors={validationErrors}
        />
      )}

      <div
        className="header-sec chat-header row  "
        style={{ width: "100%", margin: "auto" }}
      >
        <Header />

        <div className=" header-sec   ">
          {!data?.user ? (
            <ShimmerCategoryItem
              hasImage
              imageType="circular"
              imageWidth={50}
              imageHeight={25}
              title
            />
          ) : (
            <div className="  ">
              <div className="col-12 d-flex justify-content-between mt-1 mb-1 ">
                <div className="col-12 col-md-12 d-flex justify-content-start align-items-center   ">
                  <div className="online_div">
                    <img
                      src={`${process.env.REACT_APP_STORAGE_URL}${data?.user?.image}`}
                      alt=""
                      className="rounded-circle border border-white img"
                      style={{
                        width: "48px",
                        height: "48px",
                        marginLeft: "12px",
                        marginRight: "10px",
                      }}
                    />
                    {data?.user?.is_online && <div className="online"></div>}
                  </div>
                  <p className="text text-white ">{data?.user?.name}</p>
                </div>
              </div>
              <div>
                {data?.user && (
                  <p className=" last-seen ">
                    {" "}
                    آخر ظهور {formatLastActive(data?.user?.last_active_at)}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="content-below ">
        {isLoading && <MessagesLoader />}

        <div className=" sec">{renderMessagesWithDateSeparators()}</div>

        
        {record && (
          <div className="">
            <div className="">
              <RecorpPopup
                setDelpopup={setDelpopup}
                delpopup={delpopup}
                isRecording={isRecording}
                audioBlob={audioBlob}
                startRecording={startRecording}
                stopRecording={stopRecording}
                setRecord={setRecord}
                setAudioBlob={setAudioBlob}
                recordedChunks={recordedChunks}
                sendMessage={sendMessage}
              />
            </div>
          </div>
        )}
      </div>

      <MessageInput
        handleReplyClick={handleReplyClick}
        messages={messages}
        setRecord={setRecord}
        record={record}
        sendMessage={sendMessage}
        handleUploadFile={handleUploadFile}
        setReplyToMessage={setReplyToMessage}
        replyToMessage={replyToMessage}
        chatUser={data?.user}
      />

      {delpopup && (
        <PopupDelete
          onClick={setAudioBlob}
          onClickFalse={setDelpopup}
          setRecord={setRecord}
          setIsRecording={setIsRecording}
          setAudioBlob={setAudioBlob}
          recordedChunks={recordedChunks}
          setDelpopup={setDelpopup}
        />
      )}
      {closefile && (
        <PopupDelete
          setClosefile={setClosefile}
          type="file"
          setViewFile={setViewFile}
          viewFile={viewFile}
          open={open}
          setOpen={setOpen}
          sendMessage={sendMessage}
        />
      )}
    </div>
  );
}

export default SupportChat;

export const useClose = () => {
  const [open, setOpen] = useState(false);
  const mouse = useRef();
  useEffect(() => {
    const handler = (e) => {
      if (mouse.current) {
        if (!mouse.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  }, []);

  return { open, setOpen, mouse };
};
