import React, { useState, useEffect } from "react";
// import './index.css';
import Slider from "../../../components/slider";
import { FileUploader } from "react-drag-drop-files";
import addImage from "../../../assets/icons/addimage.png";
import wrongAnswer from "../../../assets/icons/wrongcheckbox.png";
import examTraditionalLogic from "./examTraditionalLogic";
import ToastSuccessError from "../../../components/ToastSuccessError/ToastSucessError";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import CountDown from "../../../components/CountDoun";
import {
  clearMsg,
  getError,
  getSuccess,
  setSuccess,
} from "../../../redux-toolkit/reducers/notification";
import { Link, useParams } from "react-router-dom";
import { Circle } from "rc-progress";
import "./examTraditional.css";
import uuid from "react-uuid";
import Questino from "../../../components/questino";
import ToastError from "../../../components/ToastSuccessError/ToastError";
import LoadingOverlay from "../../components/LoadingOverlay";
import AutomatedExam from "./AutomatedExam";

function OptimizedExamTraditional() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [timer, settimer] = useState();
  const [visibleTmer, setvisibleTmer] = useState(false);
  const [studendAnswers, setStudentAnswers] = useState([]);
  const [finishExam, setFinishExam] = useState(false);
  const [errorMess, setErrorMess] = useState("");
  const [successMessages, setSucessMessages] = useState("");
  const [LessonAutomatedWitAnswer, setLessonAutomatedWitAnswer] = useState([]);

  // Sroll to top
  useEffect(() => {
    const intervalId = setInterval(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }, 1);

    setTimeout(() => {
      clearInterval(intervalId);
    }, 50);
  }, []);
  const {
    fileTypes,
    examTime,
    startExam,
    examIsFinished,
    file,
    allFiles,
    handleChange,
    AnswersFile,
    setStartExam,
    setExamIsFinished,
    QuizQuestions,
    QuizDetails,
    SavedAnswersFiles,
    setloading,
    loading,
    loadFinish,
    progress,
    isCompressing,
    examInfos,
    isAnswerOnTradional,
    setIsAnserOnTraditional,
    automatedQuestions,
  } = examTraditionalLogic();

  const startQuiz = () => {
    setStartExam(true);
    !QuizDetails.has_started_quiz_before &&
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/start_traditional_exam_by_traditional_exam_id`,
          {
            traditional_exam_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          settimer(res?.data?.data?.remaining_time);
        })
        .catch((err) => { });
  };
  useEffect(() => {
    if (timer) {
      setvisibleTmer(true);
    } else if (QuizDetails?.quiz_remaining_time) {
      setvisibleTmer(true);
    } else {
      setvisibleTmer(false);
    }
  }, [timer, QuizDetails?.quiz_remaining_time]);
  const successMessage = useSelector(getSuccess);
  const errorMsg = useSelector(getError);

  const changeColor = (progress) => {
    if (progress <= 25) {
      return "#ff0707";
    } else if (progress <= 50) {
      return "#ff6907";
    } else if (progress <= 75) {
      return "#ffdb07";
    } else {
      return "#77ff07";
    }
  };

  const hanldeStudentAnswer = (questionID, optionID) => {
    const copyAnswers = [...studendAnswers];
    let isAnsweredBefore = false;

    // console.log('conocnocn', copyAnswers)

    for (let i = 0; i < copyAnswers.length; i++) {
      if (copyAnswers[i].aqq_id == questionID) {
        isAnsweredBefore = true;

        copyAnswers[i].option_id = optionID;
      }
    }

    if (!isAnsweredBefore) {
      copyAnswers.push({ aqq_id: questionID, option_id: optionID });
    }

    setStudentAnswers(copyAnswers);
  };

  const navigateToMyExams = () => {
    navigate("/myexams");
  }
  const postAutomatedAnswers = () => {
    if (examInfos.type == "AUTOMATED") {
      setIsLoading(true)
    }
    if (!examInfos.has_submitted_automated_quiz) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/post_the_answer_for_automated_quizzes_question`,
          {
            answers: studendAnswers,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((data) => {
          // setresult(data?.data?.result);

          if (examInfos.type == "AUTOMATED") {
            setIsLoading(false)
            setSuccess("تم إنجاز المذاكرة");

            navigateToMyExams();

          }

          if (data?.data?.message) {
            // setSucessMessages(data?.data?.message);
            // window.location.reload();
          } else {
            if (examInfos.type == "AUTOMATED") {
              setSucessMessages("تم إنجاز المذاكرة");

            }
          }
        })
        .catch((error) => {
          error?.response?.data?.message ===
            "تمت الاجابة على هذا السؤال من قبل هذا الطالب من قبل"
            ? setErrorMess(
              "تمت الاجابة على هذا السؤال من قبل هذا الطالب من قبل"
            )
            : setErrorMess(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    if (examInfos?.has_submitted_automated_quiz) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/student/automated-quizzes/${examInfos?.automated_quiz_id}/answers`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((data) => {
          // console.log("data previous student answers", data?.data?.data);
          setLessonAutomatedWitAnswer(data.data);
        })
        .catch((error) => {
          // console.log('error unit', error)
        });
    }
  }, [examInfos]);

  useEffect(() => {
    // console.log("ssstudent answers", studendAnswers);
  }, [studendAnswers]);
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />

      <ToastError
        setSuccessMessage={setSucessMessages}
        setErrorMessage={setErrorMess}
        ErrorMessage={errorMess}
        successMessage={successMessages}
      />
      {!examIsFinished && loadFinish && (
        <div className={`uploadPrgressbar`}>
          <h2>يتم الآن إرسال الأجوبة</h2>

          <div
            className="content"
            style={{ dispaly: typeof progress != "number" ? "none" : "flex" }}
          >
            {/* <Line percent={progress} strokeWidth={4} strokeColor="#37bfbd" /> */}
            <p>{progress}%</p>
            <Circle
              percent={progress}
              strokeWidth={4}
              strokeColor={changeColor(progress)}
            />
          </div>
        </div>
      )}

      <div className="examScreen">
        {/* <ScreenTitle
					title='مادة الرياضيات'
					completTitle='الوحدة الأولى'
					lessonNum={QuizDetails?.quiz_name}
					secondTitle={'مدة الانجاز'}
					secondTitleNum={
						QuizDetails?.quiz_duration > 60
							? Math.floor(QuizDetails?.quiz_duration / 60)
							: QuizDetails?.quiz_duration
					}
					// thirdTitle={'عدد النقاط'}
					// thirdTitleNum={QuizDetails?.quiz_points}
				/> */}

        <div className="examScreen__explanatino">
          <h2>شرح عن الاختبار</h2>
          <ul>
            <li>{QuizDetails && QuizDetails.description}</li>

            {/* ************************************************************************* */}
            {/* {QuizDetails &&
                        QuizDetails.quiz_description.map((item,index)=>
                        <li>
						{item.name}
						</li>
                        )
                    } */}
            {/* ************************************************************************* */}
          </ul>
        </div>
        {localStorage.getItem("role") == 1 ? (
          <>
            {startExam ? (
              <div className="examItself">
                <div className="examItself__content">
                  {startExam && QuizDetails?.quiz_remaining_time_to_end && (
                    <CountDown
                      start={startExam}
                      endTime={
                        QuizDetails && QuizDetails?.quiz_remaining_time_to_end
                          ? QuizDetails?.quiz_remaining_time_to_end
                          : timer
                      }
                    />
                  )}
                  {visibleTmer && startExam && (
                    <CountDown
                      start={startExam}
                      endTime={
                        QuizDetails && QuizDetails?.quiz_remaining_time_to_end
                          ? QuizDetails?.quiz_remaining_time_to_end
                          : timer
                      }
                    />
                  )}

                  <div class="custom-alert">
                    عزيزي الطالب  في حال كان لديك انترنت غير سريع جداً الرجاء الانتظار ريثما يتم تحميل الأسئلة دون إغلاق الصفحة أو إعادة تحديثها ,
                    الوقت المتوقع ما بين 1-7 دقيقة وهو ما تم أخذه بعين الاعتبار عند وضع الوقت الخاص بالمذاكرة.
                  </div>
                  {!visibleTmer && !QuizDetails?.quiz_remaining_time_to_end && (
                    <div class="alert alert-dark" role="alert">
                      عزيزي الطالب للأسف انتهى وقت إرسال الاجابات .. يمكنك إرسال
                      الإجابات لكن لن يتم التصحيح إلا في حال تقديم عذر مقبول
                      وموافقة الإدارة بشكل شخصي على هذا الأمر{" "}
                    </div>
                  )}

                  {/* الاختبار التقليدي اسئلة + ارفاق اجوبة */}
                  {examInfos?.type == "TRADITIONAL" ||
                    (examInfos.type == "BOTH" && isAnswerOnTradional) ? (
                    <div>
                      <div className="questions">
                        <ul>
                          <li>الأسئلة</li>
                        </ul>
                        <iframe
                          src={
                            process.env.REACT_APP_STORAGE_URL +
                            QuizDetails?.questions
                          }
                          height="700"
                          width="100%"
                        ></iframe>

                        <ul>
                          <li>أرفق الأجوبة</li>
                        </ul>

                        <div className="questions__answersContainer">
                          <div className="uploadFile">
                            <img
                              loading="lazy"
                              src={addImage}
                              className="addimage"
                              alt=""
                            />
                            <FileUploader
                              multiple={true}
                              className="holako"
                              handleChange={handleChange}
                              name="file"
                              types={fileTypes}
                            />
                          </div>

                          {isCompressing && (
                            <div className="w-100 text-center py-4 justify-content-center gap-2 flex align-items-center ">
                              <h3>
                                يرجى الانتظار ريثما ننتهي من عملية ضغط الصور{" "}
                              </h3>

                            </div>
                          )}
                          {
                            // dist ... static width of the images
                            file ? (
                              <Slider dist={200} gap={30}>
                                {allFiles.map((img, index) => {
                                  return (
                                    <img
                                      key={uuid()}
                                      loading="lazy"
                                      src={img}
                                      onClick={() => window.open(img)}
                                      className="imageAnswer"
                                      alt=""
                                    />
                                  );
                                })}
                              </Slider>
                            ) : null
                          }
                        </div>

                        {allFiles?.length > 0 && (
                          <div>({allFiles.length}) من الصور المرفقة</div>
                        )}
                      </div>

                      {examInfos.type == "TRADITIONAL" && !examIsFinished && (
                        <button
                          className="my-3 btn btn-success"
                          onClick={() => { SavedAnswersFiles(); }}
                        >
                          إنهاء الامتحان
                        </button>
                      )}

                      {examInfos.type == "BOTH" && !examIsFinished && (
                        <button
                          className="my-3 btn btn-success"
                          onClick={() => {
                            SavedAnswersFiles();
                            postAutomatedAnswers();
                          }}
                        >
                          إنهاء الامتحان
                        </button>
                      )}

                      {examInfos.type == "BOTH" && (
                        <button
                          className="btn btn-dark mx-3"
                          onClick={() => setIsAnserOnTraditional(false)}
                        >
                          رجوع
                        </button>
                      )}
                    </div>
                  ) : null}

                  {/* اظهار اجوبة الطالب مع الاجوبة الصحيحة في حال تمت الاجابة على الاختبار المؤتمت مسبقا */}
                  {examInfos?.has_submitted_automated_quiz ? (
                    <div className="lesson__questions">
                      {LessonAutomatedWitAnswer?.automated_quiz_text && (
                        <div
                          className="lesson_question_text"
                          dangerouslySetInnerHTML={{
                            __html:
                              LessonAutomatedWitAnswer?.automated_quiz_text,
                          }}
                        ></div>
                      )}
                      <ol type="1" className="questions__container">
                        {LessonAutomatedWitAnswer?.data?.map(
                          (quistion, index) => (
                            <li className={`question`} key={uuid()}>
                              <div className="question__container">
                                <div className="topsection">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: quistion.question,
                                    }}
                                  />
                                </div>

                                <div className="answers gap-5">
                                  {quistion?.the_options?.map((answer, id) => {
                                    return (
                                      <div
                                        key={uuid()}
                                        className={`answerItself ${answer.is_true == "1"
                                          ? "rightAnswer"
                                          : null
                                          } }`}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: answer.answear,
                                          }}
                                        />
                                        <input
                                          type="checkbox"
                                          className={`${answer.is_student_answer &&
                                            answer.is_true == "0"
                                            ? "hidecheckbox"
                                            : ""
                                            }`}
                                          checked={answer.is_true == "1"}
                                          value={answer.text}
                                          name={id}
                                        />
                                        <div className="statusImage">
                                          {answer.is_student_answer &&
                                            "0" == answer.is_true ? (
                                            <img
                                              loading="lazy"
                                              src={wrongAnswer}
                                              alt=""
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </li>
                          )
                        )}
                      </ol>
                    </div>
                  ) : null}

                  {examInfos?.type == "AUTOMATED" ||
                    (examInfos.type == "BOTH" && !isAnswerOnTradional) ? (
                    <div>
                      {!examInfos?.has_submitted_automated_quiz &&

                        <AutomatedExam questions={automatedQuestions} setStudentAnswers={setStudentAnswers} file={examInfos.automatedQuiz?.file} />
                        // automatedQuestions?.map((question, index) => {
                        //   return (
                        //     <Questino
                        //       studendAnswers={studendAnswers}
                        //       showAnswersToUser={
                        //         examInfos.has_submitted_automated_quiz
                        //       }
                        //       key={uuid()}
                        //       hanldeStudentAnswer={hanldeStudentAnswer}
                        //       questionId={question.id}
                        //       question={question.question}
                        //       options={question.options}
                        //       isTrue={index % 2 == 0 ? true : false}
                        //       marks={question.point}
                        //       notes={question.question_note}
                        //     />
                        //   );
                        // })}
                      }

                      {examInfos.type == "BOTH" && !examIsFinished && (
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            if (examInfos.has_submitted_automated_quiz) {
                              setIsAnserOnTraditional(true);
                            } else if (
                              studendAnswers.length < automatedQuestions?.length
                            ) {
                              setErrorMess(
                                "يرجى الاجابة على كافة الاسئلة قبل الانتقال لحل الاسئلة التقليدية"
                              );
                            } else {
                              setIsAnserOnTraditional(true);
                            }
                          }}
                        >
                          التالي
                        </button>
                      )}

                      {examInfos.type == "AUTOMATED" && (
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            if (examInfos.has_submitted_automated_quiz) {
                              setErrorMess(
                                "عزيزي الطالب لقد قمت بالإجابة على اسئلة المذاكرة مسبقا "
                              );
                            } else if (
                              studendAnswers.length < automatedQuestions?.length
                            ) {
                              if (examInfos.type == "BOTH")
                                setErrorMess(
                                  "يرجى الاجابة على كافة الاسئلة قبل الانتقال لحل الاسئلة التقليدية"
                                );
                              else
                                setErrorMess(
                                  "يجب حل كافة الأسئلة"
                                );
                            } else {
                              postAutomatedAnswers();
                            }
                          }}
                        >
                          إنهاء الامتحان
                        </button>
                      )}
                    </div>
                  ) : null}
                </div>

                <div className="finishExamBtn my-4">
                  {examIsFinished && (
                    <div className="examFinished">تم إنهاء الامتحان</div>
                  )}
                  {!examIsFinished && loadFinish ? (
                    <div class="spinner-border" role="status">
                      <span class="sr-only"></span>
                    </div>
                  ) : (
                    !examIsFinished && <div></div>
                  )}
                </div>
                {examIsFinished ? (
                  <div className="w-100 my-5">
                    <h3
                      className="px-3 text-center mx-auto alert alert-info shadow border"
                      style={{ maxWidth: "600px" }}
                    >
                      عزيزي الطالب يمكنك الحصول على سلم التصحيح عبر الدخول لقسم
                      المذاكرات من لوحة التحكم الخاصة بك وذلك بعد إنتهاء الوقت
                      المخصص للمذاكرة{" "}
                    </h3>

                    <div className="w-100 text-center">
                      <h3 className="my-2">لعرض مذاكراتك المنجزة</h3>
                      <Link to={"/myexams"} className="btn btn-primary fw-bold">
                        انقر هنا{" "}
                      </Link>
                    </div>
                  </div>
                ) : // <div className='lessonAnswers'>
                  // 	<Attachment
                  // 		img={pdfImg}
                  // 		title=' سلم التصحيح'
                  // 		ladder_file={`${process.env.REACT_APP_STORAGE_URL}/${AnswersFile}`}
                  // 		btn1Text='تحميل'
                  // 		btn2Text='تصفح'
                  // 	/>
                  // </div>
                  null}
              </div>
            ) : (
              <div className="BtnContainer">
                {loading ? (
                  <button onClick={startQuiz} className="startExamBtn">
                    {QuizDetails?.quiz_remaining_time_to_end &&
                      QuizDetails?.has_started_quiz_before
                      ? "	الاستمرار بالاختبار"
                      : "	البدء بالاختبار"}
                  </button>
                ) : (
                  <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="examItself mt-0">
              <div className="examItself__content">
                <div className="questions">
                  <ul>
                    <li>الأسئلة</li>
                  </ul>
                  <iframe
                    src={
                      process.env.REACT_APP_STORAGE_URL + QuizDetails?.questions
                    }
                    height="700"
                    width="100%"
                  ></iframe>

                  {/* <Slider>
										{QuizQuestions &&
											QuizQuestions?.map((img, index) => {
												return (
													<img loading="lazy" 
														src={`${process.env.REACT_APP_STORAGE_URL}${img.file_link}`}
														className='imageAnswer'
														alt=''
														key={index}
														onClick={() =>
															window.open(
																`${process.env.REACT_APP_STORAGE_URL}${img.file_link}`
															)
														}
													/>
												);
											})}
									</Slider> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastSuccessError
        ErrorMessage={errorMsg}
        clearMsg={clearMsg}
        successMessage={successMessage}
      />
    </>
  );
}

export default OptimizedExamTraditional;
