import { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import logo from "../../../assets/logo/logo.png";
import eraser from "../../../assets/eraser.png";
import Pen from "../../../assets/Pen.png";
import pencil from "../../../assets/pencil.png";

const AutomatedExam = ({ questions, setStudentAnswers, file }) => {
  console.log(questions)
  const [selections, setSelections] = useState({
    pencil: {},
    bluePen: {},
  });
  const [finalAnswers, setFinalAnswers] = useState({});

  const [currentTool, setCurrentTool] = useState("none");
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    certificate: "",
    studentName: "",
    motherName: "",
    grade: "",
    examNumber: "",
    subject: "",
    branch: "",
    date: "",
  });

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const handleTableSelection = (e, column, tableId) => {
    const selectedValue = e.target.value;
    const key = `${tableId}-${column}`;

    setSelections((prev) => {
      const updatedSelections = { ...prev };

      if (currentTool === "eraser") {
        if (updatedSelections.bluePen[key] === selectedValue) {
          return prev;
        }

        if (updatedSelections.pencil[key] === selectedValue) {
          delete updatedSelections.pencil[key];
        }
        if (updatedSelections.bluePen[key] === selectedValue) {
          delete updatedSelections.bluePen[key];
        }
      }

      if (currentTool === "pencil") {
        updatedSelections.pencil = {
          ...updatedSelections.pencil,
          [key]: selectedValue,
        };
      }

      if (currentTool === "bluePen") {
        updatedSelections.bluePen = {
          ...updatedSelections.bluePen,
          [key]: selectedValue,
        };
      }
      handleSubmit();
      return updatedSelections;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  function transformQuestions(questions, transformedObject) {
    const letters = ['A', 'B', 'C', 'D']; // Map letters to options index
    const result = [];

    Object.keys(transformedObject).forEach(key => {
      const questionIndex = parseInt(key, 10) - 1; // Get the question index (key - 1)
      const letter = transformedObject[key]; // Get the letter (A, B, C, D)

      if (questions[questionIndex]) {
        const question = questions[questionIndex]; // Find the corresponding question
        const optionIndex = letters.indexOf(letter); // Get the index of the letter

        if (optionIndex !== -1 && question.options[optionIndex]) {
          const option = question.options[optionIndex]; // Find the matching option
          result.push({
            aqq_id: question.id, // Question ID
            option_id: option.id // Option ID
          });
        }
      }
    });

    return result;
  }
  const handleSubmit = () => {


    console.log("Selected Options:", selections);
    console.log("Selected Options FINAL :", selections.bluePen);

    // Transform the object to use numeric keys
    const transformedObject = Object.keys(selections.bluePen)
      .filter(key => key.startsWith("optionsTable2") || key.startsWith("optionsTable")) // Filter relevant keys
      .reduce((result, key) => {
        const match = key.match(/\d+$/); // Extract numeric part at the end
        if (match) {
          result[match[0]] = selections.bluePen[key]; // Assign the value to the new numeric key
        }
        return result;
      }, {});
    const finalResult = transformQuestions(questions, transformedObject);

    console.log(finalResult)
    setStudentAnswers(finalResult)
    console.log("Transformed Object:", transformedObject);
    console.log("Form Data:", formData);
  };

  const getToolButtonClass = (tool) => {
    if (currentTool === tool) {
      switch (tool) {
        case "eraser":
          return "btn-danger";
        case "pencil":
          return "btn-warning";
        case "bluePen":
          return "btn-primary";
        default:
          return "";
      }
    }
    return "btn-secondary";
  };

  const renderTable = (tableId, startRow, endRow, type = "options") => (
    <div
      className="table-container"
      style={{ overflowX: "auto", whiteSpace: "nowrap" }}
    >
      <table className="table" style={{ minWidth: "100%" }}>
        <thead>
          <tr>
            {type === "options" ? (
              <>
                <th></th>
                <th style={{ color: "#b85757", fontWeight: "bold" }}>A</th>
                <th style={{ color: "#b85757", fontWeight: "bold" }}>B</th>
                <th style={{ color: "#b85757", fontWeight: "bold" }}>C</th>
                <th style={{ color: "#b85757", fontWeight: "bold" }}>D</th>
              </>
            ) : (
              <th
                colSpan={11}
                style={{
                  fontSize: "23px",
                  backgroundColor: "#b85757",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                I.D. NUMBER
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: endRow - startRow + 1 }, (_, rowIndex) => {
            const rowNumber = startRow + rowIndex;
            return (
              <tr key={`${tableId}-${rowNumber}`}>
                <td>{rowNumber}</td>

                {type === "options"
                  ? ["A", "B", "C", "D"].map((option) => (
                    <td key={option}>
                      <label className="radio-container">
                        <input
                          type="radio"
                          name={`${tableId}-row${rowNumber}`}
                          value={option}
                          onClick={(e) =>
                            handleTableSelection(
                              e,
                              `row${rowNumber}`,
                              tableId
                            )
                          }
                          disabled={currentTool === "none"}
                          checked={
                            selections.pencil[
                            `${tableId}-row${rowNumber}`
                            ] === option ||
                            selections.bluePen[
                            `${tableId}-row${rowNumber}`
                            ] === option
                          }
                        />
                        <span
                          className="radio-label"
                          style={{
                            backgroundColor:
                              selections.bluePen[
                                `${tableId}-row${rowNumber}`
                              ] === option
                                ? "blue"
                                : selections.pencil[
                                  `${tableId}-row${rowNumber}`
                                ] === option
                                  ? "gray"
                                  : "white",
                          }}
                        >
                          {option}
                        </span>
                      </label>
                    </td>
                  ))
                  : Array.from({ length: 10 }, (_, colIndex) => {
                    const cellValue = colIndex;
                    return (
                      <td key={`col-${rowNumber}-${colIndex}`}>
                        <label className="radio-container">
                          <input
                            type="radio"
                            name={`${tableId}-col${colIndex}`}
                            value={rowNumber}
                            onClick={(e) =>
                              handleTableSelection(
                                e,
                                `col${colIndex}`,
                                tableId
                              )
                            }
                            disabled={currentTool === "none"}
                            checked={
                              selections.pencil[
                              `${tableId}-col${colIndex}`
                              ] === `${rowNumber}` ||
                              selections.bluePen[
                              `${tableId}-col${colIndex}`
                              ] === `${rowNumber}`
                            }
                          />
                          <span
                            className="radio-label"
                            style={{
                              backgroundColor:
                                selections.bluePen[
                                  `${tableId}-col${colIndex}`
                                ] === `${rowNumber}`
                                  ? "blue"
                                  : selections.pencil[
                                    `${tableId}-col${colIndex}`
                                  ] === `${rowNumber}`
                                    ? "gray"
                                    : "white",
                            }}
                          >
                            {rowNumber}
                          </span>
                        </label>
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return (
    <>

      <div className="col-md-12 mt-3">
        <label className="form-label">الأسئلة</label>
        <embed
          src={`${process.env.REACT_APP_STORAGE_URL}${file}`}
          type="application/pdf"
          width="100%"
          height="400px"
          style={{ border: "1px solid #ddd" }}
        />
      </div>
      <Container fluid className="p-3">
        <Row>
          <Col xl lg={12} md={12} sm={12} xs={12} className="mt-10">




            {/* Logo */}
            <div className="mt-3 container">
              <div className="row justify-content-center">
                <div
                  className="col-auto d-flex justify-content-center align-items-center"
                  style={{
                    width: "100px",
                    height: "100px",
                    overflow: "hidden",
                    position: "relative",
                    border: "1px solid #b85757",
                  }}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    className="w-75"
                    style={{
                      transform: "rotate(320deg)",
                      objectFit: "contain",
                      position: "absolute",
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Notes */}
            <div className="container mt-3">
              <div className="row justify-content-center align-items-center">
                <div className="col-12 col-md-12">
                  <h1
                    className="text-center fs-4 fs-sm-6"
                    style={{
                      color: "#b85757",
                      fontWeight: "bold",
                    }}
                  >
                    ملاحظات هامة:
                  </h1>
                  <ol
                    style={{
                      color: "#b85757",
                      fontSize: "20px",
                      lineHeight: "1.8",
                      paddingLeft: "1.5rem",
                    }}
                    className="fs-6 fs-sm-5"
                  >
                    <li>نوع القلم المستخدم: قلم أزرق ناشف.</li>
                    <li>
                      الشكل الصحيح للتظليل: إشارة غامقة تملأ مستطيل الحرف
                      الموافق للرقم المُخْتار.
                    </li>
                    <li>
                      مراعاة كتابة اسم الأم والرقم والاسم الثلاثي والصف والفرع
                      والشهادة والتاريخ بشكل واضح وصحيح.
                    </li>
                    <li>عدم ثني زاوية ورقة الإجابة.</li>
                    <li>
                      ينال علامة الصفر على السؤال في حال:
                      <ul>
                        <li>ثقب الورقة</li>
                        <li>تظليل إجابتين لنفس السؤال</li>
                        <li>تظليل بدائرة فارغة أو بإشارة X أو بمربع فارغ</li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center mt-3">
              <div className="w-75">
                <h1 style={{ fontSize: "25px", color: "#b85757" }}>
                  {" "}
                  طريقة التظليل :{" "}
                </h1>
                <p style={{ fontSize: "20px", color: "#b85757" }}>
                  نبدأ بالآحاد من اليمين ثم العشرات ثم ...
                </p>
              </div>
            </div>

            <div className="container mt-5">
              <div className="row justify-content-center align-items-center flex-column">
                <div className="col-12 col-md-auto text-center">
                  <h1
                    className="mx-2"
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "#b85757",
                    }}
                  >
                    مثال:
                  </h1>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "#b85757",
                    }}
                  >
                    تظليل الرقم: ۲۱٤۳
                  </p>
                </div>

                <div className="col-12 table-responsive mt-3">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th
                          colSpan={10}
                          style={{
                            fontSize: "23px",
                            backgroundColor: "#b85757",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          I.D. NUMBER
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from({ length: 10 }, (_, rowIndex) => {
                        const rowNumber = rowIndex;
                        return (
                          <tr key={`row-${rowNumber}`}>
                            {Array.from({ length: 10 }, (_, colIndex) => {
                              const number = rowIndex;

                              let checkedValue = false;

                              if (rowIndex === 3) {
                                if (colIndex === 0) checkedValue = number === 3;
                              }
                              if (rowIndex === 4) {
                                if (colIndex === 1) checkedValue = number === 4;
                              }
                              if (rowIndex === 1 && colIndex === 2) {
                                checkedValue = number === 1;
                              }
                              if (rowIndex === 2 && colIndex === 3) {
                                checkedValue = number === 2;
                              }

                              return (
                                <td key={`col-${rowNumber}-${colIndex}`}>
                                  <label className="radio-container">
                                    <input
                                      type="radio"
                                      name={`row${rowNumber}-col${colIndex}`}
                                      value={number}
                                      checked={checkedValue}
                                    />
                                    <span className="radio-label">
                                      {number}
                                    </span>
                                  </label>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </Col>

          <Col>

            <Row className="mt-5">
              <Col xs={12} md={6} className="mb-3 mb-md-0">
                <div className="border border-danger p-1 small-table">
                  {renderTable("optionsTable", 1, 50, "options")}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="border border-danger p-1 small-table">
                  {renderTable("optionsTable2", 51, 100, "options")}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Submit Button */}
      {/* <div className="text-center mt-5">
        <button className="btn btn-success" onClick={handleSubmit}>
          Submit
        </button>
      </div> */}

      {/* Tool Buttons */}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <button
          onClick={() => setCurrentTool("eraser")}
          className={`btn ${getToolButtonClass("eraser")}`}
          title="ممحاة"
          style={{
            padding: "10px",
            minWidth: "50px",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={eraser}
            alt="Eraser"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        </button>

        <button
          onClick={() => setCurrentTool("pencil")}
          className={`btn ${getToolButtonClass("pencil")}`}
          title="قلم رصاص"
          style={{
            padding: "10px",
            minWidth: "50px",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={pencil}
            alt="Pencil"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        </button>

        <button
          onClick={() => setCurrentTool("bluePen")}
          className={`btn ${getToolButtonClass("bluePen")}`}
          title="قلم أزرق"
          style={{
            padding: "10px",
            minWidth: "50px",
            minHeight: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Pen}
            alt="Blue Pen"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
        </button>
      </div>

      {/* Modal */}
      {show && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0 me-2 mt-2"
                onClick={handleClose}
                aria-label="Close"
              ></button>
              <div className="modal-header position-relative">
                <h5 className="modal-title">تنبيهات الامتحان</h5>
              </div>

              <div className="modal-body">
                <p>يرجى التأكد من مراجعة الأسئلة بعناية قبل تسليم الإجابة.</p>
                <ul>
                  <li>مدة الامتحان: ساعتان.</li>
                  <li>عدد الأسئلة: 50 سؤالاً.</li>
                  <li>يرجى التحقق من اتصال الإنترنت.</li>
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  فهمت
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AutomatedExam;
