import {
  createAsyncThunk,
  createSlice,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";

export const postOpinionfun = createAsyncThunk(
  "postOpinion/postOpinionwrite",
  async ({ opinion }, thunkAPI) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/post_user_opinion`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: 1,
            opinion: opinion,
          }),
        }
      );
      let data = await response.json();
      if (response.ok) {
        return { ...data, opinion: opinion };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  post: [],
  loading: false,
  error: null,
  isError: "",
  isSuccess: "",
};

export const OpinionSlice = createSlice({
  name: "opinion",
  initialState,
  reducers: {
    clearMsg(state, action) {
      state.isError = "";
      state.isSuccess = "";
    },
  },
  extraReducers: {
    [postOpinionfun.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [postOpinionfun.fulfilled]: (state, action) => {
      state.loading = false;
      state.post = action.payload;
      state.isSuccess = "تم التعليق بنجاح";
    },
    [postOpinionfun.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;

      state.isError = action.payload?.message || "حدث خطأ بالشبكة";
    },
  },
});

export const getSuccess = createDraftSafeSelector(
  (state) => state.postOpinion,
  (opinion) => opinion.isSuccess
);

export const getError = createDraftSafeSelector(
  (state) => state.postOpinion,
  (opinion) => opinion.isError
);

export const getLoading = createDraftSafeSelector(
  (state) => state.postOpinion,
  (opinion) => opinion.loading
);

export const { clearMsg } = OpinionSlice.actions;
export default OpinionSlice.reducer;
